var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { id: "check_carpooling_page" }
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "spacing-item" }, [
          _c("div", [
            _vm.status == "carpool_verified"
              ? _c("div", { staticClass: "icon-container" }, [
                  _c("img", {
                    staticStyle: { opacity: "0.8" },
                    attrs: { src: _vm.basePath + "img/checked.svg", alt: "" }
                  })
                ])
              : _vm.status == "carpool_not_verified"
              ? _c("div", { staticClass: "icon-container" }, [
                  _c("img", {
                    staticStyle: { opacity: "0.8" },
                    attrs: { src: _vm.basePath + "img/cancel.svg", alt: "" }
                  })
                ])
              : _c("div", { staticClass: "icon-container finder" }, [
                  _c("div", [
                    _c("img", {
                      attrs: { src: _vm.basePath + "img/www.svg", alt: "" }
                    })
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.format_car_reg_plate(_vm.car_reg_plate || ""))
                    )
                  ])
                ]),
            _c(
              "div",
              {
                class:
                  ((_obj = {}),
                  (_obj[_vm.status] = true),
                  (_obj["verification-text"] = true),
                  _obj)
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      display: "block",
                      "text-align": "center",
                      "font-size": "24px"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.format_car_reg_plate(_vm.car_reg_plate || ""))
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      display: "block",
                      "text-align": "center",
                      "font-size": "16px"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.status == "carpool_verified"
                          ? "Carpool verified!"
                          : "Car did not carpool today!"
                      )
                    )
                  ]
                ),
                _vm.status == "carpool_not_verified"
                  ? _c(
                      "div",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c("br"),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", plain: "" },
                            on: { click: _vm.reportOwner }
                          },
                          [_c("span", [_vm._v("Report owner")])]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "spacing-item w100" },
          [
            _c(
              "el-card",
              [
                _c(
                  "span",
                  {
                    staticClass: "page-title",
                    staticStyle: { "font-size": "18px", "margin-top": "0px" }
                  },
                  [_vm._v("Check carpooling")]
                ),
                _c(
                  "el-input",
                  {
                    attrs: { placeholder: "191 D 1234" },
                    on: {
                      input: function($event) {
                        _vm.status = null
                      }
                    },
                    model: {
                      value: _vm.car_reg_plate,
                      callback: function($$v) {
                        _vm.car_reg_plate = $$v
                      },
                      expression: "car_reg_plate"
                    }
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: { click: _vm.checkCarRegPlate },
                      slot: "append"
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }