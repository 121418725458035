<template>
  <div v-loading.fullscreen.lock="loading" id="check_carpooling_page">
    <div class="container">
      <div class="spacing-item">
        <div>
          <div v-if="status == 'carpool_verified'" class="icon-container">
            <img style="opacity: 0.8" :src="`${basePath}img/checked.svg`" alt="">
          </div>

          <div v-else-if="status == 'carpool_not_verified'" class="icon-container">
            <img style="opacity: 0.8" :src="`${basePath}img/cancel.svg`" alt="">
          </div>

          <div v-else class="icon-container finder">
            <div>
              <img :src="`${basePath}img/www.svg`" alt="">
            </div>
            <span>{{ format_car_reg_plate(car_reg_plate || '') }}</span>
          </div>
          <div :class="{[status]: true, 'verification-text': true }">
            <span style="display: block; text-align: center; font-size: 24px">{{ format_car_reg_plate(car_reg_plate || '') }}</span>
            <span style="display: block; text-align: center; font-size: 16px">{{ status == 'carpool_verified' ? 'Carpool verified!' : 'Car did not carpool today!' }}</span>
            <div style="text-align:center" v-if="status == 'carpool_not_verified'">
              <br>
              <el-button @click="reportOwner" type="danger" plain><span>Report owner</span></el-button>
            </div>
          </div>
        </div>
      </div>

      <div class="spacing-item w100">
        <el-card>
          <span class="page-title" style="font-size: 18px; margin-top: 0px;">Check carpooling</span>
          <el-input @input="status = null" placeholder="191 D 1234" v-model="car_reg_plate">
            <el-button @click="checkCarRegPlate" slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    car_reg_plate: null,
    status: null, /* 'carpool_verified', 'carpool_not_verified' */
    loading: false,
    basePath: process.env.BASE_URL
  }),

  mounted() {
    this.car_reg_plate = this.$route.query.crp;

    if (!this.car_reg_plate) {
      this.status = null;
      return;
    }
    
    this.checkCarRegPlate();
  },

  methods: {
    checkCarRegPlate() {
      if ((this.car_reg_plate || '').trim() == '') {
        return this.status = null;
      }

      this.loading = true;

      this.SharoAPI({ action: 'verifyCarpoolingTrip', data: { car_reg_plate: (this.car_reg_plate || '').toLowerCase().replace(/ /g, "") }}).then(result => {
        console.log(result);
        if (result.data.success) {
          this.status = 'carpool_verified';
        } else {
          this.status = 'carpool_not_verified';
        }
      }).finally(_ => this.loading = false);
    },

    reportOwner() {
      this.loading = true;

      setTimeout(() => {
        this.$message({ type: 'success', message: 'Owner has been reported', showClose: true });
        this.status = null;
        this.car_reg_plate = null;
        this.loading = false;
      }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
#check_carpooling_page {

  & > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 120px);

    .spacing-item {
      flex: 1;
    }

    .spacing-item:first-child {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .spacing-item:last-child {
      max-width: 600px;
      flex: 1;
      min-height: 100px;
      display: flex;
      flex-direction: column-reverse;
      // border: 1px solid blue;
    }
  }

  .verification-text {
    display: none;
    margin-bottom: 40px;

    &.carpool_verified {
      display: block;
      span { color: green; }
    }

    &.carpool_not_verified {
      display: block;
      span { color: red; }
    }
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    

    img {
      max-width: 120px;
    }

    &.finder {
      flex-direction: column;

      span {
        display: block;
        text-align: center;
        font-size: 24px;
        margin-top: 10px;
      }

      div {
        background-color: #D1F1FF;
        border-radius: 50%;
        width: 160px;
        height: 160px;

        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 110px;
        }
      }
      
    }
  }
}
</style>